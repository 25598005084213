import * as React from "react"
import { Link } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
import { Grid, Text, Box } from "@fastly/consistently"
import { H1 } from "@fastly/consistently-vanilla"

import {
  main,
  mainArea,
  title,
  introTitle,
  titleSectionContainer,
  description,
  tileBox,
  tileTitle,
  moreInfo,
  tilesWrapper,
} from "~vanilla/homepage.css"

import { theRowIcon } from "~vanilla/homepage.css"

import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import { CommonHead } from "~components/Head/CommonHead"
import LayoutCore from "~templates/layout-core"

import frontpageBoxes from "~data/frontpage_boxes.yml"
import { tokens } from "@fastly/consistently-vanilla/tokens"

// these came from the /index.md page
let frontmatter = {
  // layout: "default_full",
  section: "front",
  section_safe: "front",
  title: "Fastly Documentation",
  lang: "en",
}

const FrontIndexPage = () => {
  let boxes = frontpageBoxes.map((box) => {
    return (
      <Box className={tileBox} key={box.svg}>
        <Link to={box.href} className={tileTitle}>
          <img className={theRowIcon} alt="" role="img" src={`/assets/images/icons/${box.svg}.svg`} />
          <br />
          <Text
            color={tokens.color.grey[90]}
            as="header"
            fontSize={20}
            lineHeight={tokens.lineHeight.sm}
            fontWeight={tokens.fontWeight["600"]}
            marginBottom={tokens.space.sm}
          >
            {box.title}
          </Text>
        </Link>
        <Text
          as="div"
          variant="md"
          color={tokens.color.grey[70]}
        >
          {box?.text}
        </Text>
      </Box>
    )
  })

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className={`content-content ${titleSectionContainer}`}>
                <Box maxWidth={906} pt="54px" marginBottom={tokens.space["4xl"]}>
                  <span className={introTitle}>Fastly Docs</span>
                  <H1 className={title}>
                    Get the most out of Fastly
                  </H1>
                  <p className={description}>
                    The Fastly Documentation Site presents a series of articles to help you get the most
                    out of using Fastly for your site, service, or application.
                  </p>
                </Box>
                <Box className={tilesWrapper} >
                  {boxes}
                </Box>
                <Box className={moreInfo}>
                  Need more info about our security safeguards? Check out our{" "}
                  <Link to="/products/security-measures">security measures</Link> guide.
                </Box>
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default FrontIndexPage

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <CommonHead />
    <SeoHead frontmatter={frontmatter} {...props} />
    <FavHead />
  </>
}
